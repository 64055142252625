import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Curs Presencial Guàrdia Urbana de Badalona</Title>
                <Text>
                    Us presentem el millor curs presencial per a la Guàrdia Urbana de Badalona
                    <br />
                    <br />
                    Un curs específic per preparar l'oposició del 2022, un curs que et posicionarà
                    entre els millors.
                    <br />
                    <br />
                    Un curs de temari específic i coneixements de la ciutat.
                    <br />
                    <br />
                    Al curs repassarem els conceptes més importants, basant-nos en la nostra
                    experiència en base a anys anteriors.
                    <br />
                    <br />
                    PowerPoint explicatiu
                    <br />
                    <br />
                    Explicació detallada dels conceptes més rellevants.
                    <br />
                    <br />
                    Torn de dubtes
                    <br />
                    <br />
                    Dossier inclòs pels alumnes assistents.
                    <br />
                    <br />
                    TEMARI.
                    <br />
                    <br />
                    -Constitució.
                    <br />
                    <br />
                    -Estatut.
                    <br />
                    <br />
                    -El municipi.
                    <br />
                    <br />
                    -Procediment administratiu
                    <br />
                    <br />
                    -La Llei 2/19886.
                    <br />
                    <br />
                    -La Llei 16/1991 i més.
                    <br />
                    <br />
                    A més, farem un repàs del municipi en xifres.
                    <br />
                    <br />
                    I per finalitzar el curs, un Kahoot-simulacre amb premis pels guanyadors.
                    <br />
                    <br />
                    Un curs essencial i molt necessari per posicionar-vos dels primers a Badalona.
                    <br />
                    <br />
                    Dissabte 30 d'abril
                    <br />
                    <br />
                    De 8.00 a 13.00 hores
                    <br />
                    <br />
                    A C. Martí Codolar, 18, 20, 08902 l'Hospitalet de Llobregat, Barcelona
                    <br />
                    <br />
                    T'esperem!
                </Text>
            </div>

            {/** ¿Cómo accedo? */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
